import axios from "axios";
import {message} from "antd";
import {API_URL} from "../constants";
import {authHeader, download} from "../Utilities";
import HierarchyNode from "../components/ImageViewerHandlers/Hierarchy/HierarchyNode";
import Hierarchy from "../components/ImageViewerHandlers/Hierarchy/Hierarchy";
import ObjectReference from "../components/ImageViewerHandlers/Hierarchy/ObjectReference";

import {TagReference} from "./ApiModels/TagReference";
import {SystemAttribute} from "../components/ImageViewerHandlers/ImageViewerTypes";
import {isString, StringWithAutocomplete} from "../UtilitiesTs";

type TagListItem = {
    text: string,
    label: string,
    full_load: boolean,
}

type SearchInHierarchyFilter = {
    toRequestBody?: () => any
}

type HierarchyModel = {
    id: string,
    name: string,
    hidden: boolean;
    workspace_id: string,
}

type NodeAttributeModel = {
    key: string,
    value: string
}

export type NodeLoadingInfoModel = {
    full: boolean,
    subnodes_count?: number
}

export type NodeReferenceModel = {
    id: string,
    page_id: string,
    x_rel: number,
    y_rel: number,
}

type NodeId = string;

type ExpandedSubtreeNode = {
    id: NodeId,
    text: string,
    label: string,
}

export type NodeModel = {
    attributes: NodeAttributeModel[],
    hierarchy_id: string,
    id: string,
    label: string,
    loading_info: NodeLoadingInfoModel
    parent_node_id?: string,
    references: NodeReferenceModel[],
    text: string,
}

export type NodeUpdateModel = {
    attributes: NodeAttributeModel[],
    id: string,
    label: string,
    parent_node_id?: string,
    references: NodeReferenceModel[],
    text: string,
}

type FetchPartialHierarchyResponse = {
    nodes_list: NodeModel[],
    hierarchy: HierarchyModel
}

export type ReferenceExtraData = {
    id: string,
    tag_reference?: TagReference,
    file_name: string,
    page_number: number,
}

export type HierarchySystemAttributes = {
    name: string,
    options: string[],
    label: string,
}

type AddMultipleNodeCommand = {
    type: "update" | "create",
    node: HierarchyNode
}

export type LogAuthor = {
    user: {id: string, name: string},
    date: Date
}

export type HierarchyLog = {
    id: string,
    created_by: LogAuthor,
    reverted_by?: LogAuthor,
    type: StringWithAutocomplete<"new-node" | "update-node" | "add-multiple-node" | "delete-node" | "bulk">,
}

export type HierarchyLogOperationNodeDumpRef = {
    id: string,
    page_id: string,
    x_rel: number,
    y_rel: number,
}

export type HierarchyLogOperationNodeDumpAttr = {
    key: string,
    value: string
}

export type HierarchyLogOperationNodeDump = {
    id: string,
    text: string,
    label: string,
    parent_id?: string,
    references: HierarchyLogOperationNodeDumpRef[],
    attributes: HierarchyLogOperationNodeDumpAttr[],
}

export type SetterTextAndLabel = {
    type: "text_and_label",
    data: {
        text: string,
        label: string
    }
}

export type SetterAttributes = {
    type: "attributes",
    data: HierarchyLogOperationNodeDumpAttr[]
}

export type SetterReferences = {
    type: "references",
    data: HierarchyLogOperationNodeDumpRef[]
}

export type SetterParent = {
    type: "parent",
    data: string
}

export type HierarchyLogOperationSetter = SetterTextAndLabel | SetterAttributes | SetterReferences | SetterParent | {
    type: string,
    data: any
};

interface IHierarchyLogOperationSetterTyped<T> {
    value: T
}

export class HierarchyLogOperationSetterTyped {
    private readonly _setter: HierarchyLogOperationSetter;

    constructor(setter: HierarchyLogOperationSetter) {
        this._setter = setter;
    }

    get value(): {} {
        return this._setter;
    }

    isTextAndLabel(): this is IHierarchyLogOperationSetterTyped<SetterTextAndLabel> {
        return this._setter.type == "text_and_label";
    }
}

export type HierarchyLogOperation = {
    id: string,
    type: StringWithAutocomplete<"updated" | "created" | "deleted">,
    prev?: HierarchyLogOperationNodeDump,
    current?: HierarchyLogOperationNodeDump,
    setters?: HierarchyLogOperationSetter[]
}

class HierarchiesService {
    fetchHierarchies(workspaceId: string) {
        return axios.get<HierarchyModel[]>(API_URL + `/hierarchies?workspace_id=${workspaceId}`, {headers: authHeader()});
    }

    addHierarchy(hierarchy: string) {
        return axios.post(API_URL + `/hierarchies`, hierarchy, {headers: authHeader()});
    }

    fetchHierarchyById(hierarchyId: string) {
        return axios.get(API_URL + `/hierarchies/${hierarchyId}`, {headers: authHeader()});
    }

    deleteHierarchy(hierarchyId: string) {
        return axios.delete(API_URL + `/hierarchies/${hierarchyId}`, {headers: authHeader()});
    }

    hideHierarchy(hierarchyId: string, hidden: boolean) {
        return axios.post(API_URL + `/hierarchies/${hierarchyId}/hidden`,
        { hidden: hidden },
        { headers: authHeader() });
    }

    addHierarchyNode(hierarchy: Hierarchy, node: HierarchyNode) {
        if (!node.isValid()) return null;
        return axios.post(API_URL + `/hierarchies/${hierarchy.id}/nodes`, node.getDict(), {headers: authHeader()});
    }

    updateHierarchyNode(hierarchy: Hierarchy, node: HierarchyNode) {
        if (!node.isValid()) return Promise.reject();
        const nodeDict = node.getDict();
        return this.updateHierarchyNodeRaw(hierarchy.id, {
            ...nodeDict,
            id: nodeDict.id!,
            references: nodeDict.references.map(x => ({
                ...x,
                id: x.id!
            }))
        });
    }

    updateHierarchyNodeRaw(hierarchyId: string, node: NodeUpdateModel) {
        return axios.put<NodeModel>(API_URL + `/hierarchies/${hierarchyId}/nodes/${node.id}`, node, {headers: authHeader()});
    }

    addMultipleNodes(hierarchy: Hierarchy, commands: AddMultipleNodeCommand[]) {
        if (commands.some(x => !x.node.isValid())) return Promise.reject();
        return axios.post(
            API_URL + `/hierarchies/${hierarchy.id}/multiple_nodes/`,
            {
                commands: commands.map(x => ({
                    type: x.type,
                    data: x.node.getDict()
                })),
            },
            {headers: authHeader()}
        );
    }

    deleteHierarchyNode(hierarchy: Hierarchy, node: HierarchyNode) {
        return axios.delete(API_URL + `/hierarchies/${hierarchy.id}/nodes/${node.id}`, {headers: authHeader()});
    }

    exportHierarchyToExcel(hierarchy: Hierarchy) {
        axios.post(`${API_URL}/hierarchies/${hierarchy.id}/export_to_xlsx`,
            {}, {
                headers: authHeader(),
                responseType: "blob"
            }).then((response) => {
            const blob = new window.Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            download(blob, "hierarchy_exported.xlsx");
        }).catch(() => {
            message.error("Failed to export hierarchy");
        });
    }

    getHierarchyNodeReferenceTagReference(
        hierarchy: Hierarchy | string,
        node: HierarchyNode | string,
        reference: ObjectReference | string
    ) {
        const hierarchyId = isString(hierarchy) ? hierarchy : hierarchy.id;
        const nodeId = isString(node) ? node : node.id;
        const referenceId = isString(reference) ? reference : reference.id;
        return axios.get<TagReference>(
            `${API_URL}/hierarchies/${hierarchyId}/nodes/${nodeId}/references/${referenceId}/tag_reference`,
            {headers: authHeader()}
        );
    }

    searchInHierarchy(hierarchy: Hierarchy, query: string, page: number, limit: number, filters: SearchInHierarchyFilter[] = []) {
        return axios.post(
            `${API_URL}/hierarchies/${hierarchy.id}/nodes/filtered?query=${query}&page=${page}&limit=${limit}`,
            {filters: filters.filter(x => x?.toRequestBody).map(x => x.toRequestBody!())},
            {headers: authHeader()}
        );
    }

    fetchPartialHierarchyById(hierarchyId: string, tagsList: TagListItem[]) {
        return axios.post<FetchPartialHierarchyResponse>(
            API_URL + `/hierarchies/${hierarchyId}/partial_loading/load_nodes`,
            {tags_list: tagsList}, {headers: authHeader()});
    }

    fetchNodeReferencesExtraData(hierarchy: Hierarchy, node: HierarchyNode) {
        return axios.get<ReferenceExtraData[]>(API_URL + `/hierarchies/${hierarchy.id}/nodes/${node.id}/references_extra_data`, {headers: authHeader()});
    }

    fetchSystemAttributes(hierarchyId: string): Promise<HierarchySystemAttributes[]> {
        return axios.get<SystemAttribute[]>(
            API_URL + `/hierarchies/${hierarchyId}/system_attributes`,
            {headers: authHeader()}
        ).then(({data}) =>
            data.map((x) => ({
                name: x.attribute_name,
                options: x.values_list,
                label: x.object_label
            }))
        );
    }

    getPossibleSearchOptionsUrl(hierarchyId: string) {
        return `${API_URL}/hierarchies/${hierarchyId}/nodes/possible_search_option_values`;
    }

    getHierarchyLogsByCurrentUser(hierarchyId: string, page: number, limit: number, showAll: boolean) {
        return axios.get<HierarchyLog[]>(
            API_URL + `/hierarchies/${hierarchyId}/logs/for_user`,
            {headers: authHeader(), params: {page, limit, show_all: showAll}}
        );
    }

    getHierarchyLogOperations(hierarchyId: string, logId: string, page: number, limit: number) {
        return axios.get<HierarchyLogOperation[]>(
            API_URL + `/hierarchies/${hierarchyId}/logs/${logId}/operations`,
            {headers: authHeader(), params: {page, limit}}
        );
    }

    revertHierarchyLog(hierarchyId: string, logId: string) {
        return axios.put<HierarchyLog[]>(
            API_URL + `/hierarchies/${hierarchyId}/logs/${logId}/revert`,
            {headers: authHeader()}
        );
    }

    async expandSubtree(hierarchyId: string, nodeId: string): Promise<ExpandedSubtreeNode[]> {
        const resp = await axios.get<ExpandedSubtreeNode[]>(
            `${API_URL}/hierarchies/${hierarchyId}/nodes/${nodeId}/expanded_subtree_ids`,
            {headers: authHeader()}
        );
        return resp.data;
    }
}

export default new HierarchiesService();
