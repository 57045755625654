import ImageViewerObject from "./components/ImageViewerHandlers/ImageViewerObject";
import {ImageViewerCanvasObject} from "./components/ImageViewerHandlers/ImageViewerCanvasObject";
import {ImageViewerObjectFromCanvas} from "./Utilities";
import axios from "axios";
import {RcCustomRequestOptions} from "antd/es/upload/interface";

export type StringWithAutocomplete<T> = T | (string & {});

export type Factory<T> = () => T;

export function memoized<T>(
    factory: Factory<T>,
    dirty: (current: T) => boolean = () => false
) {
    let result: undefined | T;
    return () => {
        if (result == null || dirty(result)) {
            result = factory();
        }
        return result;
    };
}

export function fork<A, B>(
    cond: boolean,
    ifTrue: A,
    otherwise: B,
): A | B {
    return cond ? ifTrue : otherwise;
}


export function isString(obj: any): obj is string {
    return typeof obj === "string";
}

export function ivObj(obj: ImageViewerObject | ImageViewerCanvasObject): ImageViewerObject {
    if (obj instanceof ImageViewerObject) {
        return obj;
    } else {
        return new ImageViewerObjectFromCanvas(obj);
    }
}

export async function axiosUploadRequest(options: RcCustomRequestOptions) {
    const { file, onSuccess, onError, onProgress, action, headers } = options;

    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(
            action,
            formData,
            {
                headers: headers,
                onUploadProgress: (event) => {
                    onProgress?.({percent: (event.loaded / event.total) * 100}, file);
                },
            }
        );

        onSuccess?.(response.data, file);
    } catch (error) {
        onError?.(error);
    }
}