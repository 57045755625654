import React from "react";
import {
  Button, Card,
  Collapse,
  Descriptions, Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Popover,
  Row,
  Space, Spin, Table,
  Tooltip, Typography
} from "antd";
import HierarchiesService from "../../../services/HierarchiesService";
import { EllipsisOutlined, EyeTwoTone, DeleteOutlined } from '@ant-design/icons';
import HierarchyNode from "../../ImageViewerHandlers/Hierarchy/HierarchyNode";
import {EditableTextField} from "../../EditableFields/EditableTextField";
import {EditableAutoCompleteField} from "../../EditableFields/EditableAutoCompleteField";
import {ObjectReferencesComponent} from "./ObjectReferenceComponent";

const { Text } = Typography;

export default class NodeData extends React.Component {
  state = {
    newVirtualNodePopoverVisible: false,
    hoveredColIndex: -1,
    hoveredRowIndex: null,
    loadingAttributeRowIndex: -1,
  }

  componentDidMount() {
    this.newAttributeNameRef = React.createRef();
  }

  handleCreateNewVirtualNode = (values) => {
    const hierarchy = this.props.hierarchy;
    HierarchiesService.addHierarchyNode(hierarchy, new HierarchyNode({
      hierarchy: hierarchy,
      text: values.new_node_text,
      label: values.new_node_label,
      parent_node_id: this.props.currentNode.id,
      references: [],
      attributes: [],
    })).then(() => {
      message.success('Virtual node added');
      this.props.hierarchyView.loadHierarchy();
    }).catch(() => {
      message.error('Failed to add virtual node');
    })
    this.setState({newVirtualNodePopoverVisible: false});
  }

  handleAddAttribute = (values) => {
    this.props.currentNode.addAttribute(
        {key: values.new_attribute_name, value: values.new_attribute_value}
    ).then(() => message.success('Attribute added')
    ).catch(() => message.error('Failed to add attribute'));
  }

  handleRemoveAttribute = (attributeKey) => {
    const attribute = this.props.currentNode.findAttributeByKey(attributeKey);
    if (!attribute) return;
    this.props.currentNode.removeAttribute(attribute).then(
        () => message.success('Attribute removed')
    ).catch(() => message.error('Failed to remove attribute'));
  }

  handleSetAttributeValue = (attributeKey, attributeValue) => {
    let updateAttribute;

    if (attributeKey === 'Class') {
      updateAttribute = (attr, callback) => this.props.currentNode.updateLabel(attr.value, callback);
    } else if (attributeKey === 'Text') {
      updateAttribute = (attr, callback) => this.props.currentNode.updateText(attr.value, callback);
    } else {
      updateAttribute = (attr, callback) => {
        if (this.props.currentNode.hasAttribute(attributeKey)) {
          return this.props.currentNode.updateAttribute(attr, callback)
        } else {
          return this.props.currentNode.addAttribute(attr, callback);
        }
      };
    }

    updateAttribute({key: attributeKey, value: attributeValue}, () => {
      this.setState({loadingAttributeRowIndex: -1});
    }).then(
        () => {
          message.success('Attribute updated');
        }
    ).catch(() => {
      message.error('Failed to update attribute');
      this.setState({loadingAttributeRowIndex: -1});
    });
  }

  handleShowNodeSubtree = (node) => {
    return this.props.hierarchyView.handleShowNodeSubtree(node);
  }

  render() {
    const systemAttributes = this.props.systemAttributes.filter(x => x.label === this.props.currentNode?.label);
    const systemAttributeNames = systemAttributes.map(x => x.name);

    const formLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const newVirtualNodeContent = (
        <div style={{width: "188px"}}>
          <Form
              {...formLayout}
              initialValues={{new_node_text: '', new_node_label: 'virtual'}}
              onFinish={this.handleCreateNewVirtualNode}
              requiredMark={false}
          >
            <Form.Item label="Text" name="new_node_text" style={{marginBottom: '4px'}}
                       rules={[{required: true, message: 'Please enter node text'}]}>
              <Input id="new-node-text" size="small" />
            </Form.Item>
            <Form.Item label="Class" name="new_node_label" style={{marginBottom: '12px'}}
                       rules={[{required: true, message: 'Please enter node class'}]}>
              <Input id="new-node-label" size="small" />
            </Form.Item>
            <Row>
              <Space>
                <Button size="small" style={{width: 90}} onClick={() => this.setState({newVirtualNodePopoverVisible: false})}>Cancel</Button>
                <Button
                    type="primary" size="small" style={{width: 90}}
                    htmlType="submit"
                >Add</Button>
              </Space>
            </Row>
          </Form>
        </div>
    );

    const tableCols = [
      {
        title: 'attribute',
        dataIndex: 'attributeName',
        width: '30%',
        render: (text, record, index) => {
          const deleteIconShown = index === this.state.hoveredColIndex && !['Class', 'Text', 'References', ...systemAttributeNames].includes(record.attributeName);
          return {
            props: {
              style: { background: "WhiteSmoke" }
            },
            children: (
              <div style={{width: 'auto', height: '100%', margin: '-8px', padding: '8px'}}
                   onMouseEnter={() => this.setState({hoveredColIndex: index})}
                   onMouseLeave={() => this.setState({hoveredColIndex: -1})}>
                {text}
                <Popconfirm
                    placement="topLeft"
                    title={`Are you sure to delete attribute '${text}'?`}
                    onConfirm={() => this.handleRemoveAttribute(text)}
                    okText="Yes" cancelText="No"
                >
                  <a style={{visibility: deleteIconShown ? 'initial' : 'hidden'}}><DeleteOutlined/></a>
                </Popconfirm>
              </div>
              )
          };
        }
      },
      {
        title: 'value',
        dataIndex: 'attributeValue',
        render: (value, record, index) => {
          const currentNode = this.props.currentNode || {
            textIsReadonly() {
              return true;
            },
            labelIsReadonly() {
              return true;
            }
          }
          if (index === this.state.loadingAttributeRowIndex) return <Spin />
          if (systemAttributeNames.includes(record.attributeName)) {
            return (
                <EditableAutoCompleteField
                    autoCompleteProps={{
                      dropdownAlign: {
                        points: ['bl', 'tl'],
                        offset: [0, -4],
                        overflow: {
                          adjustX: 0,
                          adjustY: 0
                        }
                      }
                    }}
                    value={value}
                    optionsList={systemAttributes.find(x => x.name === record.attributeName)?.options ?? []}
                    editIconShown={index === this.state.hoveredRowIndex}
                    onUpdate={(newText) => {
                      this.handleSetAttributeValue(record.attributeName, newText);
                      this.setState({loadingAttributeRowIndex: index});
                    }}
                />
            );
          }
          if (record.attributeName === 'References') return value;
          if (record.attributeName === 'Class' && currentNode.labelIsReadonly()) return value;
          if (record.attributeName === 'Text' && currentNode.textIsReadonly()) return value;
          return <EditableTextField
              value={value}
              editIconShown={index === this.state.hoveredRowIndex}
              onUpdate={(newText) => {
                this.handleSetAttributeValue(record.attributeName, newText);
                this.setState({loadingAttributeRowIndex: index});
              }}
          />
        }
      },
    ];

    const renderParent = (node) => {
      const parentNode = this.props.currentNode.getParent();
      if (!parentNode) return '';

      const renderParentAttributes = () => {
        const parentAttributes = this.props.currentNodeParentAttributes;
        return (parentAttributes && <Descriptions layout="vertical" column={1} size="small">
          {parentAttributes.map(attr => (
              <Descriptions.Item label={(<Text type="secondary">{attr.key}</Text>)}>{<span style={{marginTop: '-10px', marginBottom: '-5px'}}>{attr.value}</span>}</Descriptions.Item>
          ))}
        </Descriptions>)
      }

      return (<React.Fragment>
        <Collapse bordered={false}>
          <Collapse.Panel key="1" header={`Parent: ${parentNode.text}`}>
            <a href="#" onClick={() => this.props.hierarchyView.selectNode(parentNode)}>Switch to parent</a>
            {renderParentAttributes()}
          </Collapse.Panel>
        </Collapse>


      </React.Fragment>)
    }

    const tableData = [
      {
        attributeName: 'Class',
        attributeValue: this.props.currentNode?.label,
      },
      {
        attributeName: 'Text',
        attributeValue: this.props.currentNode?.text,
      },
    ];

    tableData.push({
      attributeName: 'References',
      attributeValue: this.props.currentNode && (
          <ObjectReferencesComponent
              node={this.props.currentNode}
              hierarchyView={this.props.hierarchyView}
          />
      )
    })

    const curAttrs = this.props.currentNode?.attributes || [];
    tableData.push(...systemAttributes.map(systemAttr => {
      const savedAttr = curAttrs.find(curAttr => curAttr.key === systemAttr.name);
      if (savedAttr) {
        return {
          attributeName: savedAttr.key,
          attributeValue: savedAttr.value
        };
      } else {
        return {
          attributeName: systemAttr.name,
          attributeValue: null
        }
      }
    }));
    tableData.push(...curAttrs
        .filter(attr => !systemAttributeNames.includes(attr.key))
        .map(attr => ({attributeName: attr.key, attributeValue: attr.value}))
    );

    const newAttributePopoverContent = (
        <div style={{width: "188px"}}>
          <Form
              {...formLayout}
              onFinish={(values) => {
                this.handleAddAttribute(values);
                this.setState({newAttributePopoverVisible: false});
              }}
          >
            <Form.Item label="Name" name="new_attribute_name" style={{marginBottom: '4px'}}>
              <Input size="small" ref={this.newAttributeNameRef} />
            </Form.Item>
            <Form.Item label="Value" name="new_attribute_value" style={{marginBottom: '12px'}}>
              <Input size="small" />
            </Form.Item>
            <Row>
              <Space>
                <Button size="small" style={{width: 90}} onClick={() => this.setState({newAttributePopoverVisible: false})}>Cancel</Button>
                <Button
                    type="primary" size="small" style={{width: 90}}
                    htmlType="submit"
                >Add</Button>
              </Space>
            </Row>
          </Form>
        </div>
    );

    const menu = (this.props.currentNode &&
        <Menu>
          <Menu.Item>
            <Popover
                content={newVirtualNodeContent}
                title="Add virtual node"
                trigger="click"
                visible={this.state.newVirtualNodePopoverVisible}
                onVisibleChange={visible => {
                  this.setState({
                    newVirtualNodePopoverVisible: visible,
                  })}}

            >
              <a>Add virtual subnode</a>
            </Popover>
          </Menu.Item>
          <Menu.Item>
            <Popover
                content={newAttributePopoverContent}
                title="Add new attribute"
                trigger="click"
                visible={this.state.newAttributePopoverVisible}
                onVisibleChange={visible => {
                  this.setState({
                    newAttributePopoverVisible: visible,
                    newAttribute: {
                      name: '',
                      value: '',
                    }});
                  if (visible) {
                    setTimeout(() => this.newAttributeNameRef.current.select(), 100);
                  }
                }}
            >
              <a>Add attribute</a>
            </Popover>
          </Menu.Item>
          {!this.props.currentNode.isRoot() && <Menu.Item>
            <a onClick={() => this.props.hierarchyView.handleSetChild({
              target: this.props.currentNode,
              targetType: 'node',
            })}>Set as child</a>
          </Menu.Item>
          }
          {this.props.currentNode.isLeaf() &&
          <Menu.Item danger>
            <Popconfirm title="Are you sure to delete the node？"
                        onConfirm={() => {
                          HierarchiesService.deleteHierarchyNode(this.props.currentNode.hierarchy, this.props.currentNode).then(() => {
                            message.success('The node removed');
                            this.props.hierarchyView.loadHierarchy();
                          }).catch((err) => {
                            message.error('Failed to remove node');
                            console.log(err);
                          });
                        }}>
              <div>Delete node</div>
            </Popconfirm>
          </Menu.Item>}
        </Menu>)

    return (
      <Card id="node-data"
            title="Node data"
            size="small"
            extra={(
                <React.Fragment>
                  {
                    this.props.currentNode && (
                        <Space>
                          <React.Fragment>
                            <Tooltip title="Show node subtree">
                              <Button
                                  size="small" icon={<EyeTwoTone twoToneColor='rgb(0,127,197,1)'/>}
                                  onClick={() => this.handleShowNodeSubtree(this.props.currentNode)}
                              />
                            </Tooltip>
                            <Button size="small" onClick={() => this.props.hierarchyView.handleSetParent(this.props.currentNode)}>Set as parent</Button>
                            <Dropdown overlay={menu}
                                      trigger="click">
                              <Button id="node-data-more-button" size="small"><EllipsisOutlined style={{fontSize: '16px'}} /></Button>
                            </Dropdown>
                          </React.Fragment>
                        </Space>
                    )
                  }

                </React.Fragment>
            )}
      >
        <Table
            id="object-attributes"
            dataSource={tableData}
            columns={tableCols}
            size="small"
            pagination={false}
            showHeader={false}
            bordered={true}
            style={{marginBottom: '8px'}}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: event => this.setState({hoveredRowIndex: rowIndex}),
                onMouseLeave: event => this.setState({hoveredRowIndex: null}),
              }
            }}
        />
        {this.props.currentNode ? renderParent(this.props.currentNode): ''}
      </Card>)
  }
}
