import React from "react";
import {Button, Form, Input, message, Row, Space} from "antd";
import HierarchyNode from "../../ImageViewerHandlers/Hierarchy/HierarchyNode"
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import HierarchiesService from "../../../services/HierarchiesService";
import {ViewHierarchy} from "./ViewHierarchy";

interface NewVirtualNodeFormProps {
    node: HierarchyNode;
    hierarchy: Hierarchy;
    viewHierarchy: ViewHierarchy;
    closePopover: () => void
}

export const NewVirtualNodeForm: React.FC<NewVirtualNodeFormProps> = ({
    node,
    hierarchy,
    viewHierarchy,
    closePopover
}) => {
    const [form] = Form.useForm();

    const handleCreateNewVirtualNode = (values: any) => {
            const result = HierarchiesService.addHierarchyNode(hierarchy, new HierarchyNode({
                hierarchy: hierarchy,
                text: values.new_node_text,
                label: values.new_node_label,
                parent_node_id: node.id,
                references: [],
                attributes: [],
            }));

            if (result) {
                result.then(() => {
                    message.success('Virtual node added');
                    viewHierarchy.loadHierarchy();
                }).catch(() => {
                    message.error('Failed to add virtual node');
                });
            } else {
                message.error('Node is not valid');
            }
            closePopover()
    };

    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      };

    return (
        <div style={{ width: "188px" }}>
            <Form
                form={form}
                {...formLayout}
                initialValues={{ new_node_text: '', new_node_label: 'virtual' }}
                requiredMark={false}
                onFinish={handleCreateNewVirtualNode}
            >
                <Form.Item
                    label="Text"
                    name="new_node_text"
                    style={{ marginBottom: '4px' }}
                    rules={[{ required: true, message: 'Please enter node text' }]}
                >
                    <Input id="new-node-text" size="small" />
                </Form.Item>
                <Form.Item
                    label="Class"
                    name="new_node_label"
                    style={{ marginBottom: '12px' }}
                    rules={[{ required: true, message: 'Please enter node class' }]}
                >
                    <Input id="new-node-label" size="small" />
                </Form.Item>
                <Row>
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={closePopover}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            style={{ width: 90 }}
                            onClick={() => form.submit()}
                        >
                            Add
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    );
};
